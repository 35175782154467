<template>
  <div>
    <div v-for="(item, index) in teacherList" :key="index" class="card">
      <div
        @click="toDetail(item, item.isSchAppt)"
        :class="{ 'can-click': !item.isSchAppt }"
        class="flex card_header"
      >
        <d-avatar :size="48" :src="item.lecturerPhoto" class="img"></d-avatar>
        <div class="carmsg">
          <p class="title">{{ item.title }}</p>
          <span class="teacher">
            <span class="shchool">{{ item.schoolName }}</span>
            <span>{{ item.lecturerName }}</span>
          </span>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="card_footer flex">
        <span class="teacher">
          {{ new Date(item.gmtStart).toLocaleDateString().replace(/\//g, "-") }}
          {{ new Date(item.gmtStart).toLocaleTimeString() }}
          {{}} &nbsp; 开始</span
        >
        <span
          class="reserved"
          v-text="item.isBook ? '取消预约' : '预约'"
          @click.stop="reserved(item)"
          :class="{ active: item.isBook }"
        ></span>
      </div>
    </div>
    <!-- 预约成功弹框 -->
    <reserve-success ref="rSuccess"></reserve-success>
    <!-- 取消预约弹框 -->
    <reserve-cancel ref="rCancel"></reserve-cancel>
    <!-- 互动预约弹窗 -->
    <el-dialog
      custom-class="interact-dialog"
      v-model="interactvisible"
      width="600px"
      title="课堂预约"
      :close-on-click-modal="false"
    >
      <div class="title">
        课堂名称：
        <span class="msg">{{ recText.title }}</span>
      </div>
      <div class="title">
        开课时间：
        <span class="msg">{{ recText.gmtStartName }} </span>
      </div>
      <div class="title">
        预约类型：
        <el-radio v-model="interactType" label="1">直播课堂</el-radio>
        <el-radio v-model="interactType" label="2">互动类型</el-radio>
      </div>
      <div class="title" v-show="interactType === '2'">
        参与教室：
        <div v-loading="clsLoading">
          <el-cascader
            ref="myCascader"
            v-model="HDclass"
            :options="interactCList"
            @change="handleChange"
            :props="{ value: 'Id', label: 'Name', emitPath: false }"
          ></el-cascader>
        </div>
      </div>
      <template v-slot:footer>
        <el-button type="primary" @click="toDig">预约</el-button>
      </template>
    </el-dialog>
    <!-- 课程详情弹窗 -->
    <el-dialog
      v-if="openDetail"
      v-model="openDetail"
      title="课程详情"
      width="1200px"
      :close-on-click-modal="false"
      append-to-body
    >
      <rec-detail
        @bookByDetail="reserved"
        :detailInfo="detailInfo"
      ></rec-detail>
    </el-dialog>
  </div>
</template>
<script>
import * as UtilsJs from "@/scripts/utils";
import ReserveSuccess from "@/components/DlgSuccess/index";
import ReserveCancel from "@/components/DlgCancel/index";
import RecDetail from "./detail.vue";
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { ElMessage } from "element-plus";
import AuthLive from "@/api/live";
import rtmUser from "@/api/RTMuser";
export default defineComponent({
  components: {
    ReserveSuccess,
    ReserveCancel,
    RecDetail,
  },
  props: {
    teachers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // 参与教室遮罩
      clsLoading: false,
      teacherList: [],
      reserveddialog: false,
      interactvisible: false,
      recText: {},
      interactType: "1",
      HDclass: [],
      interactCList: [],
      interactC: "",
      openDetail: false,
      detailInfo: {},
    };
  },
  computed: {
    ...mapState(["userinfo"]),
  },
  watch: {
    teachers: {
      deep: true,
      handler(newVal) {
        this.handleList(newVal);
      },
    },
  },
  mounted() {
    this.handleList(this.teachers);
  },
  methods: {
    handleList(val) {
      this.teacherList = val.map((item) => {
        return {
          ...item,
          gmtStartName: UtilsJs.timeDealer(item.gmtStart),
        };
      });
    },
    async reserved(item) {
      const index = this.teacherList.findIndex((r) => r.liveId === item.liveId);
      if (this.userinfo.userId) {
        if (item.isFake) {
          const index = this.teacherList.findIndex(
            (r) => r.liveId === item.liveId
          );
          if (!item.isBook) {
            this.$refs.rSuccess.open(item);
          } else {
            this.$refs.rCancel.open(item);
          }
          this.$emit("fakeRefresh", index, item.isBook);
        } else {
          if (!item.isBook) {
            if (item.type === 2) {
              this.interactvisible = true;
              const { title, gmtStart, gmtStartName, schoolId, liveId } = item;
              this.recText = {
                title,
                gmtStart,
                gmtStartName,
                schoolId,
                liveId,
              };
              const res = await rtmUser.GetHDRoomTree();
              if (res && res.Data) {
                this.Setcascader(res.Data);
              }
            } else {
              const res = await AuthLive.bookLive({
                liveId: item.liveId,
                schoolId: item.schoolId,
                classRoomId: item.classRoomId,
              });
              if (res && res.code === 200) {
                this.teacherList[index].isBook =
                  !this.teacherList[index].isBook;
                this.$refs.rSuccess.open(item);
              }
            }
          } else {
            const res = await AuthLive.cancelBook({
              liveId: item.liveId,
            });
            if (res && res.code === 200) {
              this.teacherList[index].isBook = !this.teacherList[index].isBook;
              this.$refs.rCancel.open(item);
            }
          }
        }
      } else {
        ElMessage.warning({
          message: "请先登录",
          type: "warning",
        });
      }
    },
    toDetail(item, isSchAppt) {
      if (!isSchAppt) {
        this.detailInfo = item;
        this.openDetail = true;
      }
    },
    async toDig() {
      const res = await AuthLive.bookLive({
        classRoomId: this.HDclass,
        liveId: this.recText.liveId,
        schoolId: this.recText.schoolId,
        device:
          this.interactType === "1"
            ? ""
            : this.$refs.myCascader.getCheckedNodes()[0].data.SerialNo,
      });
      if (res.code === 200) {
        this.interactvisible = false;
        this.$refs.rSuccess.open(this.recText);
        this.RefreshList();
      }
    },
    // 级联处理
    Setcascader(data) {
      this.clsLoading = true;
      let campus = [...data.Campuses];
      let builds = [...data.Buddings];
      let classes = [...data.ClassRooms];
      let jnum = -1; //当前楼栋索引
      let knum = -1; //当前楼层索引
      //遍历学院
      for (let i = 0; i < campus.length; i++) {
        jnum = -1;
        campus[i].children = [];
        // 遍历楼
        for (let j = 0; j < builds.length; j++) {
          knum = -1;
          if (builds[j].PId === campus[i].Id) {
            jnum++;
            campus[i].children.push({ ...builds[j] });
            campus[i].children[jnum].children = [];
          } else {
            continue;
          }
          // 遍历班级
          for (let k = 0; k < classes.length; k++) {
            if (classes[k].PId === builds[j].Id) {
              const temp = campus[i].children[jnum].children.filter(
                (item) => item.Id === classes[k].Floor
              );
              // 如果楼层不存在则添加
              if (!temp.length) {
                campus[i].children[jnum].children.push({
                  Id: classes[k].Floor,
                  PId: classes[k].PId,
                  Name: classes[k].Floor,
                  children: [],
                });
                knum++;
              }
              // 班级添加
              campus[i].children[jnum].children[knum].children.push({
                Id: classes[k].Id,
                PId: classes[k].Floor,
                Name: classes[k].Name,
                SerialNo: classes[k].SerialNo,
              });
            }
          }
        }
      }
      this.interactCList = campus;
      this.clsLoading = false;
    },
    //刷新列表
    RefreshList() {
      this.$emit("getData", {
        state: 0,
        startMin: String(Date.parse(new Date())),
      });
    },
  },
});
</script>
<style lang="scss" scoped>
.card {
  box-sizing: border-box;
  margin-bottom: 20px;
  width: 100%;
  height: 130px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  .card_header {
    height: 89px;
    padding-right: 12px;
    &.can-click {
      cursor: pointer;
    }
    .carmsg {
      flex: 1;
    }
    .img {
      padding: 0 10px;
    }
    .title {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .teacher {
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
  }
  .shchool {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    width: 120px;
  }
  .card_footer {
    height: 39px;
    justify-content: space-between;
    padding: 0 10px;
    .reserved {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #3984fc;
      cursor: pointer;
      &.active {
        color: #ff9800;
      }
    }
  }
}
.el-divider {
  width: 265px;
  margin: 0 auto;
}
.interact-dialog {
  .msg {
    color: #333333;
  }
}
//dialog
// .dialog_title {
//   font-size: 36px;
//   font-family: Source Han Sans CN;
//   font-weight: 400;
//   text-align: center;
//   line-height: 60px;
// }
// .Reserved_title {
//   margin-top: 20px;
//   font-size: 18px;
//   font-family: Source Han Sans CN;
//   font-weight: 400;
//   color: #666666;
//   text-align: center;
// }
</style>
<style lang="scss">
.interact-dialog {
  .el-dialog__header {
    border-bottom: 1px solid #dddddd;
  }
  .el-dialog__body {
    padding: 30px 60px;
    line-height: 40px;
  }
  .el-dialog__footer {
    padding: 10px 20px 40px;
    text-align: center;
    .el-button {
      padding: 6px 25px;
      min-height: initial;
    }
  }
}
</style>

