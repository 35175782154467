<template>
  <el-dialog
    custom-class="reserve-dialog"
    v-model="visible"
    width="600px"
    :show-close="false"
    :close-on-click-modal="false"
  >
    <div class="success-text">
      <i class="el-icon-success"></i>
      预约成功
    </div>
    <div class="tips">{{ courseMsg.title }}</div>
    <div class="flex smsgg">
      <div>
        <div>
          <span>学校：</span>
          {{ courseMsg.schoolName }}
        </div>
        <div>
          <span>年级：</span>
          {{ courseMsg.gradeName }}
        </div>
        <div>
          <span>时间：</span>
          {{ courseMsg.timeFormat }}
        </div>
      </div>
      <div>
        <div class="flex">
          <div class="stitle">教师：</div>
          <div class="iwidth">{{ courseMsg.lecturerName }}</div>
        </div>
        <div class="flex">
          <div class="stitle">学科：</div>
          <div class="iwidth">{{ courseMsg.subjectName }}</div>
        </div>
        <div class="flex">
          <div>预约类型：</div>
          <div class="iwidth">
            {{ courseMsg.type === 1 ? "直播课堂" : "互动课堂" }}
          </div>
        </div>
      </div>
    </div>
    <div class="code">
      <img src="../../assets/images/qrcode.jpg" />
    </div>
    <div class="weixin">
      微信扫码关注，开启上课提醒
      <br />
      <label>注：请妥善保管二维码，勿发给他人</label>
    </div>
    <template v-slot:footer>
      <el-button @click="RefreshList" type="primary" style="width: 180px">
        我知道了
      </el-button>
      <!-- <div>
        <span class="flabel" @click="cancelDig">取消预约</span>
      </div> -->
    </template>
  </el-dialog>
</template>

<script>
import * as UtilsJs from "@/scripts/utils.js";

export default {
  data() {
    return {
      courseMsg: "",
      visible: false,
    };
  },
  methods: {
    open(msg) {
      this.courseMsg = msg;
      this.visible = true;
      this.courseMsg.timeFormat = UtilsJs.timeDealer(msg.gmtStart);
    },
    RefreshList() {
      this.visible = false;
      // this.$parent.RefreshList();
    },
  },
};
</script>
<style lang="scss" scoped>
.success-text {
  font-size: 30px;
  color: #67c23a;
}
.smsgg {
  width: 100%;
  align-items: initial;
  justify-content: space-between;
  line-height: 25px;
  .tips {
    color: #2b2929;
  }
  .iwidth {
    width: 85px;
  }
  .stitle {
    width: 70px;
    text-align: end;
  }
}
</style>
