<template>
  <el-dialog
    custom-class="reserve-dialog"
    v-model="visible"
    width="520px"
    :show-close="false"
    :close-on-click-modal="false"
  >
    <div class="warning-text">
      <i class="el-icon-warning"></i>
      取消预约
    </div>
    <div class="tips">
      您已经取消预约：<span class="title">{{ courseName.title }}</span>
    </div>
    <div>
      <el-button @click="RefreshList" type="primary" style="width: 180px">
        我知道了
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      courseName: "",
      visible: false,
    };
  },
  methods: {
    open(name) {
      this.courseName = name;
      this.visible = true;
    },
    RefreshList() {
      this.visible = false;
      // this.$parent.RefreshList()
    },
  },
};
</script>
<style lang="scss" scoped>
.warning-text {
  margin-top: 30px;
  font-size: 30px;
  color: #f27200;
}
.tips {
  margin: 40px 0 !important;
  .title {
    color: #2b2929;
  }
}
</style>
