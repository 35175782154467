<template>
  <div>
    <div class="header">
      <div class="header-left">
        <img :src="details.cover ? details.cover : defaultCover" alt="" />
      </div>
      <div class="header-right">
        <div class="right-top">
          <div class="one">
            <el-tag type="warning">
              {{ details.gradeName }}·{{ details.subjectName }}
            </el-tag>
            <div class="otitle">
              {{ details.title }}
            </div>
          </div>
          <div class="two">
            <el-tag
              v-for="item in details.knowledgeJson"
              :key="item.chapterId"
              type="info"
            >
              {{ item.name }}
            </el-tag>
          </div>
          <div class="three">
            <div>课堂类型：直播课堂</div>
            <div class="tright">
              开课时间：
              {{ details.gmtStartName }}
            </div>
          </div>
        </div>
        <div class="right-bottom">
          <el-button
            @click="handleBook"
            type="primary"
            size="small"
            class="btn"
          >
            {{ details.isBook ? "取消预约" : "预约" }}
          </el-button>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-left">
        <div class="con-title">课程描述</div>
        {{ details.description }}
      </div>
      <div class="content-right">
        <div class="con-title">老师介绍</div>
        <div class="lect-con">
          <div class="avatar">
            <img
              :src="
                details.lecturerPhoto ? details.lecturerPhoto : defaultAvatar
              "
              alt=""
            />
          </div>

          <div class="lect-right">
            <div class="font-red">{{ details.lecturerName }}</div>
            <div>{{ details.schoolName }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as UtilsJs from "@/scripts/utils";
export default {
  name: "RecDetail",
  props: {
    detailInfo: Object,
  },
  data() {
    return {
      details: {},
      defaultCover: require("@/assets/imgs/live_default_big.png"),
      defaultAvatar: require("@/assets/imgs/default_avatar.png"),
    };
  },
  mounted() {
    this.details = this.detailInfo;
    this.details.gmtStartName = UtilsJs.timeDealer(this.detailInfo.gmtStart);
  },
  methods: {
    handleBook() {
      this.$emit("bookByDetail", this.details);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  &-left {
    width: 400px;
    height: 200px;
    border: 1px solid #d7d7d7;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-right {
    width: calc(100% - 420px);
    height: 200px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .right-top {
      min-height: 110px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .one {
        display: flex;
        align-items: flex-start;
        color: #333333;
        font-size: 24px;
        .otitle {
          margin-left: 10px;
        }
      }
      .three {
        width: 100%;
        display: flex;
        .tright {
          margin-left: 200px;
        }
      }
    }
    .btn {
      padding: 0 20px;
    }
  }
}
.content {
  margin-top: 40px;
  display: flex;
  .con-title {
    font-size: 18px;
    color: #333333;
    margin-bottom: 40px;
  }
  &-left {
    width: 860px;
    color: #666666;
    font-size: 14px;
    line-height: 25px;
  }
  &-right {
    margin-left: 20px;
    .lect-con {
      width: 260px;
      height: 120px;
      padding-left: 20px;
      border: 1px solid #eeeeee;
      display: flex;
      align-items: center;

      .avatar,
      .avatar img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }
      .lect-right {
        height: 70%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-left: 20px;
      }
    }
  }
}
::v-deep .el-tag.el-tag--warning {
  padding: 0 10px;
  height: 28px;
  text-align: center;
  color: #f27200;
  background: #fef1e5;
  border-radius: 2px;
}
::v-deep .el-tag.el-tag--info {
  padding: 0 10px;
  height: 28px;
  background: #eeeeee;
  border-radius: 2px;
}
.font-red {
  color: #f27200;
}
</style>